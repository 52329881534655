.container {
  min-height:625px;
  height: auto;
  padding: 10px;
  background-color: var(--background-color);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.joinUsHeadingBox {
  background: linear-gradient(var(--gradient-red), var(--gradient-dark-red));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  flex-basis: 100%;
  display: flex;
  font-family: var(--title-font-family);
  justify-content: center;
  align-items: center;
  margin: 35px 0px;
}

.leftContainer{
  align-items: center;
  justify-content: center;
  flex-basis: auto;
  display: flex;
}

.imageBox {
  position: relative;
  align-items: center;
  justify-content: center;
  flex-basis: auto;
  display: flex;
  width: 260px;
}

.image {
  z-index: 4;
  height: 260px;
  width: 260px;
  object-fit: cover;
  background-color: antiquewhite;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}

.descriptionContainer {
  background: linear-gradient(var(--primary-color), var(--gradient-dark-red));
  height: 220px;
  display: flex;
  top: 90%;
  position: absolute;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: -5%;
  right: -5%;
  z-index: 3;
  padding: 10px;
}

.joinUstextAlign {
  text-align: right;
  color: white;
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
}

.readMore {
  width: 50%;
  align-self: flex-end;
}

@media screen and (min-width: 768px) {
  .container{
    height: 700px;
  }

  .joinUsHeadingBox {
    justify-content: flex-end;
    align-items: flex-start;
    position: absolute;
    text-align: left;
    top: 0%;
    right: -35%;
    margin: unset;
  }

  .leftContainer{
    justify-content: center;
    align-items: center;
  }

  .imageBox {
    width:45%;
    height: 50%;
    left: -17%;
    top:-10%;
  }

  .image {
    height: 100%;
    width: 100%;   
  }

  .descriptionContainer {
    height: 60%;
    width: 80%;   
    top: 60%;
    left: 80%;
    z-index: 5;
  }

  .joinUsHeading {
    flex-basis: 20%;
  }


}

 @media screen and (min-width: 1024px) {
  .imageBox {
    width:40%;
    height: 65%;
  }
  .descriptionContainer{  
    padding: 20px;
    min-height: 240px;
  }
  
}

@media screen and (min-width:1440px){
  .container{
    height: 800px;
  }
 
  .descriptionContainer{
    padding: 30px;
    min-height: 300px;
  }
}

@media screen and (min-width:1920px){
  .container{
    height: 1080px;
  }
}

@media screen and (min-width:2560px){
  .container{
    height: 1300px;
  }
}
